import React, { Component } from "react";
import fimg from "../../pictures/f-logo.png";
import fimg2 from "../../pictures/f-img.png";
import "./styles.scss";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

class BottomNav extends Component {
	gotoRealWesbite(action) {
		window.location.href = action;
	}

	render() {
		return (
			<div className="bottom-nav">
				<div className="website-pages-bg">
					<div className="website-pages">
						<div className="website-pages-column">
							<img src={fimg} alt="" />
							<img src={fimg2} alt="" />
						</div>
						<div className="website-pages-column">
							<a className="website-pages-nav" href="https://oman.om/%D8%A7%D9%84%D9%85%D8%A4%D8%B3%D8%B3%D8%A7%D8%AA-%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D9%8A%D8%A9">
								المؤسسات الحكومية
							</a>
							<a className="website-pages-nav" href="https://oman.om/%D8%A7%D9%84%D8%AA%D9%86%D9%85%D9%8A%D8%A9-%D8%A7%D9%84%D9%85%D8%B3%D8%AA%D8%AF%D8%A7%D9%85%D8%A9">
								التنمية المستدامة
							</a>
							<a className="website-pages-nav" href="https://oman.om/home-top-level/%D8%A7%D9%84%D8%A8%D9%8A%D8%A7%D9%86%D8%A7%D8%AA-%D8%A7%D9%84%D9%85%D9%81%D8%AA%D9%88%D8%AD%D8%A9">
								البيانات المفتوحة
							</a>
							<a className="website-pages-nav" href="https://oman.om/%D8%A7%D9%84%D9%82%D8%B7%D8%A7%D8%B9%D8%A7%D8%AA">
								القطاعات
							</a>
							<a className="website-pages-nav" href="https://oman.om/%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%B3%D8%A7%D8%AA-%D9%88%D8%A7%D9%84%D9%82%D9%88%D8%A7%D9%86%D9%8A%D9%86-%D9%88%D8%A7%D9%84%D8%A3%D9%86%D8%B8%D9%85%D8%A9">
								السياسات والقوانين والأنظمة
							</a>
						</div>
						<div className="website-pages-column">
							<a className="website-pages-nav" href="https://oman.om/home-upper-level/%D8%B9%D9%86-%D8%A7%D9%84%D8%A8%D9%88%D8%A7%D8%A8%D8%A9">
								عن البوابة
							</a>
							<a className="website-pages-nav" href="https://oman.om/home-upper-level/%D8%A7%D9%84%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9">
								المساعدة
							</a>
							<a className="website-pages-nav" href="https://oman.om/home-upper-level/%D8%B3%D9%87%D9%88%D9%84%D8%A9-%D8%A7%D9%84%D9%88%D8%B5%D9%88%D9%84">
								سهولة الوصول
							</a>
							<a className="website-pages-nav" href="https://oman.om/home-top-level/%D8%AE%D8%A7%D8%B1%D8%B7%D8%A9-%D8%A7%D9%84%D8%A8%D9%88%D8%A7%D8%A8%D8%A9">
								خارطة البوابة
							</a>
							<a className="website-pages-nav" href="https://oman.om/home-upper-level/%D8%A7%D9%84%D8%AF%D8%B9%D9%85-%D9%88%D8%A7%D9%84%D8%AA%D9%88%D8%A7%D8%B5%D9%84">
								الدعم والتواصل
							</a>
							<a className="website-pages-nav" href="https://oman.om/home-upper-level/%d8%a7%d8%ad%d8%b5%d8%a7%d8%a6%d9%8a%d8%a7%d8%aa-%d8%a7%d9%84%d8%a8%d9%88%d8%a7%d8%a8%d8%a9">
								إحصائيات البوابة
							</a>
						</div>
						<div className="website-pages-column">
							<a
								className="website-pages-nav"
								href="https://oman.om/home-top-level/%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%A7%D9%84%D9%85%D8%AA%D9%83%D8%A7%D9%85%D9%84%D8%A9/%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%A7%D9%84%D8%A5%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A%D8%A9/%D9%85%D9%83%D8%AA%D8%A8-%D8%A7%D9%84%D9%85%D8%B9%D9%84%D9%88%D9%85%D8%A7%D8%AA-%D8%A7%D9%84%D9%85%D8%B1%D9%83%D8%B2%D9%8A-%D9%84%D8%AA%D9%82%D9%86%D9%8A%D8%A9-%D8%A7%D9%84%D9%85%D8%B9%D9%84%D9%88%D9%85%D8%A7%D8%AA"
							>
								مكتب المعلومات المركزي لتقنية المعلومات
							</a>
							<a
								className="website-pages-nav"
								href="https://oman.om/home-top-level/%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%A7%D9%84%D9%85%D8%AA%D9%83%D8%A7%D9%85%D9%84%D8%A9/%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%A7%D9%84%D8%A5%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A%D8%A9/%D8%A5%D8%B3%D8%AA%D8%B1%D8%A7%D8%AA%D9%8A%D8%AC%D9%8A%D8%A9-%D8%B9%D9%85%D8%A7%D9%86-%D8%A7%D9%84%D8%B1%D9%82%D9%85%D9%8A%D8%A9"
							>
								إستراتيجية عمان الرقمية
							</a>
							<a
								className="website-pages-nav"
								href="https://oman.om/home-top-level/%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%A7%D9%84%D9%85%D8%AA%D9%83%D8%A7%D9%85%D9%84%D8%A9/%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%A7%D9%84%D8%A5%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A%D8%A9/%D8%A8%D8%B1%D9%86%D8%A7%D9%85%D8%AC-%D8%A7%D9%84%D8%AA%D8%AD%D9%88%D9%84-%D8%A7%D9%84%D8%B1%D9%82%D9%85%D9%8A"
							>
								برنامج التحول الرقمي
							</a>
							<a className="website-pages-nav" href="https://mtcit.gov.om/ITAPortal_AR/ITA/default.aspx">
								وزارة النقل والاتصالات وتقنية المعلومات
							</a>
							<a className="website-pages-nav" href="https://oman.om/%D8%AF%D9%84%D9%8A%D9%84-%D8%A7%D9%84%D8%AA%D8%B7%D8%A8%D9%8A%D9%82%D8%A7%D8%AA-%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D9%8A%D8%A9">
								دليل التطبيقات العمانية
							</a>
						</div>
						<div className="website-pages-column last">
							<div className="column-row">
								<p style={{margin: 0, fontWeight: 600}}>معلومات الاتصال</p>
							</div>
							<div className="column-row">
								<label className="free-text">مركز الأتصالات:{" "}</label>
								<a className="contact-us-num" href="tel:80077997">
									80077997
								</a>
								<label> - </label>
								<a className="contact-us-num" href="tel: 80079009">
									80079009
								</a>
							</div>
							<div className="column-row">
								<label className="free-text">فاكس: 1472 ; ص. ب: 1472</label>
							</div>
							<div className="column-row">
								<label className="free-text">الرمز البريدي: 130, Azaiba </label>
							</div>
							<div className="column-row">
								<label className="free-text">
									البريد الإلكتروني للتواصل:{" "}
									<a className="contact-us-num" href="mailto:info@pacp.gov.om">
										info@pacp.gov.om
									</a>
									.
								</label>
							</div>
							<div className="column-row">
								<InstagramIcon className="reach-us-icon" onClick={() => (window.location.href = "https://www.instagram.com/cpa_oman")} />
								<TwitterIcon className="reach-us-icon" onClick={() => (window.location.href = "https://www.twitter.com/mtcitoman")} />
								<LinkedInIcon className="reach-us-icon" onClick={() => (window.location.href = "https://www.linkedin.com/company/mtcitoman")} />
							</div>
						</div>
					</div>
				</div>
				<div className="website-rights">
					<div className="rights-value"></div>
					<span className="rights-value">Copyright © 2023 Ministry of Transport, Communications and Information Technology</span>
					<span className="rights-value"></span>
				</div>
			</div>
		);
	}
}

export default BottomNav;
